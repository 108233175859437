<template>
  <div>
    <pathway-list-add-new
      :is-add-new-pathway-sidebar-active.sync="isAddNewPathwaySidebarActive"
      :visibility-options="visibilityOptions"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <pathway-list-filters
      :visibility-filter.sync="visibilityFilter"
      :status-filter.sync="statusFilter"
      :visibility-options="visibilityOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewPathwaySidebarActive = true">
                <span class="text-nowrap">Add Pathway</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPathwayListTable"
        class="position-relative"
        :items="fetchPathways"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Pathway -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolvePathwayStatusVariant(data.item.status)}`"
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.id } }"
                rounded
              />
            </template>
            <b-link
              :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ (data.item.sessions || []).length }} total sessions</small>
          </b-media>
        </template>

        <!-- Column: Live Sessions -->
        <template #cell(live_sessions)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.sessions.filter((session) => session.status === 'live').length
            }}</span>
          </div>
        </template>
        <!-- Column: Complete Sessions -->
        <template #cell(complete_sessions)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.sessions.filter((session) => session.status === 'completed').length
            }}</span>
          </div>
        </template>
        <!-- Column: Total Sessions -->
        <template #cell(total_sessions)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sessions.length }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolvePathwayRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolvePathwayRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge pill :variant="`light-${resolvePathwayVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolvePathwayStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-roster-pathway-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="archivePathway(data.item.id)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPathways"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import PathwayListFilters from './PathwayListFilters.vue';
import usePathwaysList from './usePathwaysList';
import rosterStoreModule from '../rosterStoreModule';
import PathwayListAddNew from './PathwayListAddNew.vue';

export default {
  components: {
    PathwayListFilters,
    PathwayListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    archivePathway(pathwayId) {
      store
        .dispatch('app-roster/updatePathway', {
          pathwayId,
          pathwayData: { status: 'archived' },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  setup() {
    const PATHWAY_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PATHWAY_APP_STORE_MODULE_NAME))
      store.registerModule(PATHWAY_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) store.unregisterModule(PATHWAY_APP_STORE_MODULE_NAME);
    });

    const isAddNewPathwaySidebarActive = ref(false);

    const visibilityOptions = [
      { label: 'Internal', value: 'Public' },
      { label: 'Public', value: 'Public' },
    ];

    const statusOptions = [
      { label: 'Created', value: 'created' },
      { label: 'Pending Approval', value: 'pending_approval' },
      { label: 'Approved', value: 'approved' },
      { label: 'Live', value: 'live' },
      { label: 'Retired', value: 'Retired' },
    ];

    const {
      fetchPathways,
      tableColumns,
      perPage,
      currentPage,
      totalPathways,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayListTable,
      refetchData,

      // UI
      resolvePathwayRoleVariant,
      resolvePathwayRoleIcon,
      resolvePathwayVisibilityVariant,
      resolvePathwayStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = usePathwaysList();

    return {
      // Sidebar
      isAddNewPathwaySidebarActive,

      fetchPathways,
      tableColumns,
      perPage,
      currentPage,
      totalPathways,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePathwayRoleVariant,
      resolvePathwayRoleIcon,
      resolvePathwayVisibilityVariant,
      resolvePathwayStatusVariant,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
  metaInfo() {
    return {
      title: 'Pathways',
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
